import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';

import { useEventListener } from '../../utils/hooks';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const NavigationHeaderAlgolia = dynamic(() => import('../../tissues/navigation-header'));
const CheckoutHeader = dynamic(() => import('../../molecules/checkout-header'));
const NavigationMenu = dynamic(() => import('../../tissues/navigation'));
// TODO: [TEC-8711] uncomment the code below to add quicklinks bar back
// @see https://maisonette.atlassian.net/browse/TEC-8711
// const MobileNavigationMenu = dynamic(() => import('../../tissues/mobile-navigation'));
const PetiteDropDown = dynamic(() => import('../../tissues/petite-dropdown'));
const PromotionBanner = dynamic(() => import('../../tissues/promotion-copy'));

const StickyContainer = styled.div`
  position: sticky;
  z-index: ${(props) => props.theme.layers.downstage - 1};
  top: 0;
`;

const SlidingContainer = styled.div`
  position: sticky;
  z-index: ${(props) => props.theme.layers.downstage - 4};
  top: ${(props) => props.isPetiteDropDown ? '146px' : '118px'};
  transition: transform ${(props) => props.theme.animation.slow} ease;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    top: 124px;
  }
`;

const Vestibule = (props) => {
  const sliderRef = useRef();
  let ticking = false;
  let previousY = 0;
  const isNew10PDPVisible = useFeatureIsOn('pdp-new10-banner');

  const requestTick = useCallback(() => {
    const { current } = sliderRef;

    if (current && !ticking) {
      window.requestAnimationFrame(() => {
        const currentY = window.scrollY;
        const isScrollingDown = currentY > previousY;
        
        if (current.style && currentY !== previousY) {
          if (isScrollingDown && currentY > 100) {
            current.style.transform = 'translate3d(0, -120px, 0)';

            if (props.isPetiteDropdownActive) {
              current.style.maxHeight = '0';
            }
          } else {
            current.style.transform = 'translate3d(0, 0, 0)';
            current.style.maxHeight = 'none';
          }
        }

        previousY = currentY;
        ticking = false;
      });
    }

    ticking = true;
  }, [sliderRef, props.isPetiteDropdownActive]);

  const renderPromotionBanner = props.showPromotion ? !(props.isMobilePaidPDP && isNew10PDPVisible) : false;

  useEventListener('scroll', requestTick);
  
  return (
    <>
      <StickyContainer>
        {
          props.isCheckout
            ? (
              <>
                <CheckoutHeader />
                <SlidingContainer ref={sliderRef}>
                  {props.showPromotion && ( 
                    <PromotionBanner content={props.promotionContent} /> 
                  )}
                </SlidingContainer>
              </>
            )
            : (
              <>
                <NavigationHeaderAlgolia />
              </>
            )
        }
      </StickyContainer>

      { props.user?.spree_api_key && <PetiteDropDown /> }

      {
        !props.noNav && (
          // TODO: props.noNav and props.isCheckout are only passed into the checkout layout
          // this is an artifact from checkout revamp and can be refactored
          <>
            <NavigationMenu
              navigation={props.navigation}
            />
            
            <SlidingContainer ref={sliderRef} isPetiteDropDown={props.user?.spree_api_key && props.minis?.length}>
              {/* {
                TODO: [TEC-8711] uncomment the code below to add quicklinks bar back
                @see https://maisonette.atlassian.net/browse/TEC-8711

                !props.isMobilePaidPDP && props.mobileNavigation !== null && (
                  <MobileNavigationMenu
                    navigation={props.mobileNavigation}
                    profile={props.profile}
                    isCheckout={props.isCheckout}
                  />
                )
              } */}
              {renderPromotionBanner && (
                <PromotionBanner
                  content={props.promotionContent}
                  isNavigationActive={props.isNavigationActive}
                />
              )}
            </SlidingContainer>
          </>
        )
      }
    </>
  )
};

Vestibule.defaultProps = {
  promotionContent: [],
  user: {},
  profile: {},
  isCheckout: false,
  noNav: false,
  isPetiteDropdownActive: false,
  isNavigationActive: false,
  isMobilePaidPDP: false,
  showPromotion: true
};

Vestibule.propTypes = {
  navigation: PropTypes.object.isRequired,
  mobileNavigation: PropTypes.array.isRequired,
  promotionContent: PropTypes.array,
  user: PropTypes.object,
  profile: PropTypes.object,
  isCheckout: PropTypes.bool,
  noNav: PropTypes.bool,
  isPetiteDropdownActive: PropTypes.bool,
  isNavigationActive: PropTypes.bool,
  isMobilePaidPDP: PropTypes.bool,
  showPromotion: PropTypes.bool,
  minis:PropTypes.array
};

const mapStateToProps = (state) => ({
  user: state.user,
  profile: state.profile,
  isPetiteDropdownActive: state.interfaces.isPetiteDropdownActive,
  isNavigationActive: state.interfaces.isNavigationActive,
  showPromotion: state.promotions.showPromotion,
  minis: state.petites.minis,
});

const ConnectedVestibule = connect(mapStateToProps, null)(Vestibule);

Vestibule.whyDidYouRender = true;

export default ConnectedVestibule;
